import { useQuery } from '@tanstack/react-query'
import { fetchPublicPartner, fetchPartner } from 'utility/queries/partner'

export const usePublicPartner = (slugId) =>
  useQuery({
    enabled: slugId ? true : false,
    queryKey: ['publicPartner', slugId],
    queryFn: () => fetchPublicPartner({ slug: slugId }),
  })

export const usePartner = (partnerId) =>
  useQuery({
    enabled: partnerId ? true : false,
    queryKey: ['partner', partnerId],
    queryFn: () => fetchPartner({ id: partnerId }),
  })
