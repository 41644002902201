import { useEffect, useRef } from 'react'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { usePublicPartner } from 'hooks/api/usePartner'

export const useRedirectInactivePartner = () => {
	const router = useRouter()
	const { data: tenantProfile } = useTenantProfile()
	const { slug: partnerSlug } = router.query
	const { data } = usePublicPartner(partnerSlug)
	const publicPartner = data?.public

	const redirecting = useRef(false)

	useEffect(() => {
		if (!partnerSlug || !publicPartner || !tenantProfile || redirecting.current) return

		if (publicPartner?.isActive === false) {
			redirecting.current = true
			router.replace(tenantProfile.websiteUrl || '/').then(() => {
				redirecting.current = false
			})
			// toast.success('This partner has been disabled')
		}
	}, [publicPartner, tenantProfile, partnerSlug])

	return null
}
